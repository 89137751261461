import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import { PlannerGrid } from '../common/planner-grid';
import { Button } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { formatDate, pureFormatDate } from '$lib/dateHelpers';
import { RoutePlannerDocument, RoutePlannerQuery } from '$typings/graphql-codegen';
import { getXlsx } from '$lib/excelHelpers';
import { isSomething } from '$lib/helpers';
import { saveBlob } from '$lib/fileHelpers';
import print from 'print-js';

interface IRouteTableProps {
  selectedSites: string[];
  addedSelectedSites: string[];
}

const getSummary = async (
  // wrapped in a promise because it might be a big calculation
  selectedSiteChannels: number[],
  data?: RoutePlannerQuery['siteChannels']['edges']
) => {
  if (!data) {
    return new Promise<Map<string, string>>((_, reject) => reject('no data'));
  }
  const channelsToSum = data.filter((channel) =>
    selectedSiteChannels.includes(channel.siteChannelId)
  );

  const summaryValues = new Map<string, string>();

  return new Promise<Map<string, string>>((resolve) => {
    const freecapSum = Math.round(
      channelsToSum.reduce(
        (sum, item) => sum + (item.tankDetails?.freeCapacity ?? 0),
        0
      ) ?? 0
    ).toString();

    const totalcapSum = Math.round(
      channelsToSum.reduce((sum, item) => sum + (item.capacity ?? 0), 0) ?? 0
    ).toString();

    summaryValues.set('freecap', freecapSum);
    summaryValues.set('totalcap', totalcapSum);

    return resolve(summaryValues);
  });
};

type ExportStatus = 'not_started' | 'running' | 'success' | 'error';

interface IDownloadIconData {
  textKey: string;
  icon: string;
}

//prettier-ignore
const downloadIconState = new Map<ExportStatus, IDownloadIconData>([
  ['not_started', { textKey: 'UI_SiteList_Route_DownloadAsExcel', icon: 'fa-file-excel-o' }],
  ['success', { textKey: 'UI_SiteList_Route_DownloadAsExcel', icon: 'fa-check' }],
  ['error', { textKey: 'UI_Common_Try_Again', icon: 'fa-file-excel-o' }],
  ['running', { textKey: 'UI_SiteList_Route_DownloadAsExcel', icon: 'fa-pulse fa-spinner' }]
]);

const RouteTableComponent: FC<IRouteTableProps> = ({
  selectedSites,
  addedSelectedSites,
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedSiteChannels, setSelectedSiteChannels] = useState<number[]>(
    []
  );

  const [exportStatus, setExportStatus] = useState<ExportStatus>('not_started');

  const { data } = useGridData(
    RoutePlannerDocument,
    { siteIds: selectedSites },
    (data) => data.siteChannels.totalCount,
    (data) => data.siteChannels.edges
  );

  const updateSelectedSiteChannels = () => {
    if (!data) {
      return;
    }

    // data has all available sitechannels filtered by selected sites.
    const filteredSelectedSiteChannels = data
      .map((s) => s.siteChannelId)
      .filter((siteChannelId) => selectedSiteChannels.includes(siteChannelId));

    // find newly added sitechannelids based on addedSiteIds
    const addedSiteChannels = data
      .filter(
        (siteChannel) =>
          !siteChannel.isParked &&
          addedSelectedSites.includes(siteChannel.siteId?.toString() ?? '')
      ) // add new site channels, but exclude parked
      .map((s) => s.siteChannelId)
      .filter((id) => !filteredSelectedSiteChannels.includes(id));

    const updated = [...filteredSelectedSiteChannels, ...addedSiteChannels];

    const old = JSON.stringify([...selectedSiteChannels].sort());
    const _new = JSON.stringify([...updated].sort());
    const isChanged = old !== _new;
    if (isChanged) {
      setSelectedSiteChannels(updated);
    }
  };

  useEffect(() => {
    exportStatus !== 'running' && setExportStatus('not_started');
  }, [selectedSiteChannels]);

  useEffect(() => {
    updateSelectedSiteChannels();
  }, [data]);

  const getAllSelectableRowIds = () => {
    return data?.map((sc) => sc.siteChannelId) ?? [];
  };

  const summaryValues = getSummary(
    selectedSiteChannels,
    data as RoutePlannerQuery['siteChannels']['edges']
  );

  const getError = (
    row: RoutePlannerQuery['siteChannels']['edges'][0]
  ): string | undefined => {
    const errors: string[] = [];

    if (!row.tankDetails?.vehicleId) errors.push('vehicle');
    // if (!row.site?.projectCode) errors.push('projectcode');
    // if (!row.site?.soldTo) errors.push('sold-to');
    // if (!row.product?.staalduinenProductMap) errors.push('staalduinen mapping');
    // if (!row.product?.density) errors.push('density for product');

    return errors.length ? 'Missing ' + errors.join(', ') : undefined;
  };

  const dataWithErrorField = data?.map((item) => ({
    ...item,
    error: getError(item as RoutePlannerQuery['siteChannels']['edges'][0]),
  }));

  const exportToExcel = useCallback(async () => {
    setExportStatus('running');
    const exportDate = new Date();

    const columns = [
      'Site name',
      'Product',
      'Address',
      'Postcode',
      'Country',
      'Customer name',
      'Ship to',
      'Reference',
      'Latitude',
      'Longitude',
      'Available',
      'Unit name',
      'Reach empty',
    ];

    //prettier-ignore
    const exportItems = data?.filter(sc => selectedSiteChannels.includes(sc.siteChannelId))
      .map(siteChannel => [
        siteChannel.site?.alias ?? '',
        siteChannel.product?.name,
        (siteChannel.site?.addressLine1) ?? '' + ' ' + (siteChannel.site?.addressLine2 ?? ''),
        siteChannel.site?.postcode ?? '',
        siteChannel.site?.country ?? '',
        siteChannel.site?.customer?.name ?? '',
        siteChannel.tankDetails?.shipTo ?? '',
        siteChannel.site?.customerRef ?? '',
        siteChannel.controller.lat?.toFixed(4) ?? '',
        siteChannel.controller.lng?.toFixed(4) ?? '',
        siteChannel.tankDetails?.freeCapacity ? Math.round(siteChannel.tankDetails.freeCapacity) : '',
        siteChannel.unit?.translationKey ? t(siteChannel.unit.translationKey) : '',
        siteChannel.tankDetails?.reachEmpty ? formatDate(siteChannel.tankDetails.reachEmpty, true, '.') : ''
      ]) ?? [];

    const exportTitle = 'Route planned - ' + formatDate(exportDate, true, '.');

    const merged = [[exportTitle], [], [], columns, ...exportItems];

    const XlsxPopulate = await getXlsx();
    const workbook = await XlsxPopulate.fromBlankAsync();

    const sheet = workbook.sheet('Sheet1');
    const range = sheet.range(1, 1, merged.length, columns.length);
    range.value(merged);

    for (let i = 0; i < columns.length; i++) {
      const lengths = merged.map((d) => {
        const item = d[i];
        return isSomething(item) ? item.toString().length : 0;
      });
      const longest = Math.max(...lengths);
      sheet.column(i + 1).width(longest);
    }

    // set styles
    sheet.row(1).style({ bold: true });

    const document = await workbook.outputAsync();
    saveBlob(
      new Blob([document]),
      `routeplan-${formatDate(exportDate, true, '-')}.xlsx`
    );
    setExportStatus('success');
  }, [data, selectedSiteChannels]);

  // prettier-ignore
  const printRoutePlan = async () => {
    const renderRow = (
      row: RoutePlannerQuery['siteChannels']['edges'][0],
      i: number
    ) => `
          <tr>
            <td>${i + 1}</td>
            <td>${row.site?.alias || ''}</td>
            <td>${row.site?.addressLine1 || '' + ' ' + (row.site?.addressLine2 || '')}</td>
            <td>${row.tankDetails?.freeCapacity ? Math.round(row.tankDetails.freeCapacity) : ''}</td>
            <td>${row.capacity ? Math.round(row.capacity) : ''}</td>
            <td>${row.tankDetails?.reachEmpty ? formatDate(row.tankDetails.reachEmpty, true, '.') : ''}</td>
          </tr>
        `;

    const getMarker = (
      row: RoutePlannerQuery['siteChannels']['edges'][0],
      i: number
    ) => row.controller.lat && row.controller.lng ? `&markers=color:blue%7Clabel:${i + 1}%7C${row.controller.lat},${row.controller.lng}` : '';

    const renderMap = () => `
            <img class='map' src='https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=roadmap${data
      ?.map(getMarker)
      .join('')}&key=AIzaSyD5d7KDmuxUcVlk-SBq9_zfGFT0JKyJnV0' />
        `;

    const html = `
            <h1><img src='/images/logo/logo-small.svg' alt='Yara logo'>Routeplanner</h1>
            ${renderMap()}
            <table width='100%' cellpadding='1' cellspacing='1'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Site</th>
                        <th>Address</th>
                        <th>Free capacity</th>
                        <th>Capacity</th>
                        <th>Reach empty</th>
                    </tr>
                </thead>
                <tbody>
                    ${data?.map(renderRow).join('')}
                </tbody>
            </table>
        `;

    const css = `
            h1 {
                display: flex;
                align-items: center;
            }
            
            h1 img {
                margin-right: 10px;
            }

            table {
                border-collapse: collapse;
            }

            table, th, td {
                border: 1px solid black;
                padding 10px;
            }

            .map {
                width: 100%;
                margin-bottom: 10px;
            }
        `;

    await print({
      type: 'raw-html',
      printable: html,
      style: css
    });
  };

  return (
    <PlannerGrid
      name={'route-planner'}
      getRowKey={(d) => d?.siteChannelId}
      data={dataWithErrorField}
      selected={selectedSiteChannels}
      setSelected={setSelectedSiteChannels}
      getAllSelectableRowIds={getAllSelectableRowIds}
      summaryFields={['freecap', 'totalcap']}
      summaryValues={summaryValues}
      footerText={t('ui_planner_tank_selected', {
        count: selectedSiteChannels.length,
      })}
      footerActionButtons={
        <>
          <Button
            disabled={
              !selectedSiteChannels.length || exportStatus === 'running'
            }
            variant={'contained'}
            startIcon={
              <Icon name={downloadIconState.get(exportStatus)?.icon ?? ''} />
            }
            onClick={exportToExcel}
          >
            {t(downloadIconState.get(exportStatus)?.textKey ?? '')}
          </Button>
          <Button
            sx={{marginLeft: "1rem"}}
            disabled={!data}
            variant={'contained'}
            startIcon={<Icon name={'fa-print'} />}
            onClick={printRoutePlan}
          >
            {t('UI_SiteList_Route_Print')}
          </Button>
        </>
      }
      columns={[
        {
          columnKey: 'sitename',
          columnTitle: t('ui_planner_table_header_site_name'),
          render: (item, width) => (
            <GridCell width={width}>{`${item.site?.alias}`}</GridCell>
          ),
          initialWidth: 250,
        },
        {
          columnKey: 'product',
          columnTitle: t('ui_planner_table_header_product'),
          render: (item, width) => (
            <GridCell width={width}>
              {`${item.product?.name ?? 'No product'}`}
            </GridCell>
          ),
          initialWidth: 100,
        },
        {
          columnKey: 'freecap',
          columnTitle: t('ui_planner_table_header_free_capacity'),
          render: (item, width) => (
            <GridCell width={width}>
              {Math.round(item.tankDetails?.freeCapacity ?? 0)}
            </GridCell>
          ),
          initialWidth: 80,
        },
        {
          columnKey: 'totalcap',
          columnTitle: t('ui_planner_table_header_total_capacity'),
          render: (item, width) => (
            <GridCell width={width}>{Math.round(item.capacity ?? 0)}</GridCell>
          ),
          initialWidth: 80,
        },
        {
          columnKey: 'reachempty',
          columnTitle: t('ui_planner_table_header_reach_empty'),
          render: (item, width) => (
            <GridCell width={width}>
              {item.tankDetails?.reachEmpty &&
                pureFormatDate(item.tankDetails?.reachEmpty, false, '.')}
            </GridCell>
          ),
          initialWidth: 135,
        },
      ]}
    />
  );
};

export const RouteTable = memo(
  RouteTableComponent
) as typeof RouteTableComponent;
