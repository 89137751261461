import React, { FC, memo, useState } from 'react';
import { PlannerPage } from '../common/planner-page';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { RouteTable } from '$pages/planner/route-planner/route-planner-grid';

interface IRoutePlannerProps {}

const RoutePlannerComponent: FC<
  IRoutePlannerProps
> = ({}: IRoutePlannerProps) => {
  const [t] = useCaseInsensitiveTranslation();
  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [addedSelectedSites, setAddedSelectedSites] = useState<string[]>([]);

  const updateSelectedSites = (sites: string[]) => {
    const added = sites.filter((s) => !selectedSites.includes(s));
    setAddedSelectedSites(added);
    setSelectedSites(sites);
  };

  return (
    <PlannerPage
      name={"route"}
      header={t('UI_SiteList_Route_Title')}
      selectedSitesChanged={updateSelectedSites}
      select='site'
    >
      {selectedSites.length > 0 ? (
        <RouteTable
          selectedSites={selectedSites}
          addedSelectedSites={addedSelectedSites}
        />
      ) : (
        <div className={'mar_m'}>
          {t('UI_SiteList_Route_NoSitesSelected')}
        </div>
      )}
    </PlannerPage>
  );
};

export const RoutePlanner = memo(RoutePlannerComponent);
